import React from 'react';
import * as S from './References.styles';
import { GithubOutlined, LinkedinOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const GithubIcon = S.withStyles(GithubOutlined);
const LinkedinIcon = S.withStyles(LinkedinOutlined);

export const References: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.ReferencesWrapper>
      <S.Text>
        {`${t('Made by')} `}
        <a href="https://www.mhsw.com.br/" target="_blank" rel="noreferrer">
          MHSW{' '}
        </a>
        {t('in')} 2023 &copy;. {t('Based on')}{' '}
        <a href="https://altence.com" target="_blank" rel="noreferrer">
          Altence{' '}
        </a>
      </S.Text>
      <S.Icons>
        <a href="https://github.com/renatomh" target="_blank" rel="noreferrer">
          <GithubIcon />
        </a>
        <a href="https://www.linkedin.com/in/renato-marino-henz-a9081785/" target="_blank" rel="noreferrer">
          <LinkedinIcon />
        </a>
      </S.Icons>
    </S.ReferencesWrapper>
  );
};
